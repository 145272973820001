import { FC, useMemo } from "react";
import { Box, SvgIcon, SvgIconProps } from "@mui/material";

import {
  AddUser,
  AlertCircle,
  AlignHorizontalJustifyEnd,
  ArrowUp,
  ArrowDown,
  CalendarCheck,
  CalendarRange,
  Ban,
  BellRing,
  Audio,
  Case,
  CalendarDays,
  Cancel,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUp,
  Circle,
  CircleHelp,
  Clock,
  Close,
  Columns,
  Copy,
  CopyPlus,
  Database,
  Delete,
  Download,
  Edit,
  Exit,
  Eye,
  File,
  FileDigit,
  FileText,
  FileSearch,
  FileWarning,
  Filter,
  FolderOpen,
  Formula,
  Grid,
  Groups,
  Gauge,
  HardDrive,
  Hash,
  Home,
  Image,
  InsertAbove,
  InsertBelow,
  Key,
  Layers,
  LayoutList,
  Link,
  LineChart,
  List,
  ListPlus,
  Lock,
  ManageUsers,
  MapPin,
  Minus,
  MinusSquare,
  Move,
  MoveVertical,
  Plus,
  PlusCircle,
  Refresh,
  RemoverUser,
  Reset,
  Return,
  Rss,
  Save,
  Scale,
  Search,
  Settings,
  Summarize,
  Table,
  Tag,
  Text,
  TextSelect,
  ToggleLeft,
  Transfer,
  Trash,
  Type,
  UnLock,
  UpDown,
  Upload,
  Users,
  Url,
  Video,
  Warning,
  XSquare,
  Pencil
} from "./feather";

export type ApplicationIconName =
  | "alert-circle"
  | "align-horizontal-justify-end"
  | "align-horizontal-justify-end"
  | "insert-above"
  | "insert-below"
  | "arrow-up"
  | "arrow-down"
  | "audio"
  | "ban"
  | "bell-ring"
  | "calendar-check"
  | "calendar-days"
  | "calendar-range"
  | "cancel"
  | "case"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevrons-up"
  | "circle"
  | "circle-help"
  | "clock"
  | "close"
  | "columns"
  | "copy"
  | "copy-plus"
  | "database"
  | "delete"
  | "download"
  | "edit"
  | "exit"
  | "eye"
  | "file-digit"
  | "file-search"
  | "file-text"
  | "file-warning"
  | "file"
  | "filter"
  | "folder-open"
  | "formula"
  | "grid"
  | "groups"
  | "gauge"
  | "hardDrive"
  | "hash"
  | "home"
  | "image"
  | "key"
  | "layers"
  | "layout-list"
  | "link"
  | "line-chart"
  | "list-plus"
  | "list"
  | "lock"
  | "map-pin"
  | "minus-square"
  | "minus"
  | "minus"
  | "move-vertical"
  | "move"
  | "pencil"
  | "plus-circle"
  | "plus"
  | "refresh"
  | "reset"
  | "return"
  | "rss"
  | "save"
  | "scale"
  | "search"
  | "settings"
  | "summarize"
  | "table"
  | "tag"
  | "text-select"
  | "text"
  | "toggle-left"
  | "transfer"
  | "trash"
  | "type"
  | "unLock"
  | "up-down"
  | "upload"
  | "url"
  | "userAdd"
  | "userRemove"
  | "users-manage"
  | "users"
  | "video"
  | "warning"
  | "x-square";

const iconMap: Record<ApplicationIconName, FC> = {
  "alert-circle": AlertCircle,
  "align-horizontal-justify-end": AlignHorizontalJustifyEnd,
  "arrow-up": ArrowUp,
  "arrow-down": ArrowDown,
  "bell-ring": BellRing,
  "calendar-check": CalendarCheck,
  "calendar-days": CalendarDays,
  "calendar-range": CalendarRange,
  "circle-help": CircleHelp,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevrons-up": ChevronsUp,
  "copy-plus": CopyPlus,
  "file-digit": FileDigit,
  "file-search": FileSearch,
  "file-text": FileText,
  "file-warning": FileWarning,
  "folder-open": FolderOpen,
  "insert-above": InsertAbove,
  "insert-below": InsertBelow,
  "layout-list": LayoutList,
  "line-chart": LineChart,
  "list-plus": ListPlus,
  "map-pin": MapPin,
  "minus-square": MinusSquare,
  "move-vertical": MoveVertical,
  "plus-circle": PlusCircle,
  "text-select": TextSelect,
  "toggle-left": ToggleLeft,
  "up-down": UpDown,
  "users-manage": ManageUsers,
  "x-square": XSquare,
  audio: Audio,
  ban: Ban,
  case: Case,
  cancel: Cancel,
  check: Check,
  circle: Circle,
  clock: Clock,
  close: Close,
  columns: Columns,
  copy: Copy,
  database: Database,
  delete: Delete,
  download: Download,
  edit: Edit,
  exit: Exit,
  eye: Eye,
  file: File,
  filter: Filter,
  formula: Formula,
  grid: Grid,
  groups: Groups,
  gauge: Gauge,
  hardDrive: HardDrive,
  hash: Hash,
  home: Home,
  image: Image,
  key: Key,
  layers: Layers,
  link: Link,
  list: List,
  lock: Lock,
  minus: Minus,
  move: Move,
  pencil: Pencil,
  plus: Plus,
  refresh: Refresh,
  reset: Reset,
  return: Return,
  rss: Rss,
  save: Save,
  scale: Scale,
  search: Search,
  settings: Settings,
  summarize: Summarize,
  table: Table,
  tag: Tag,
  text: Text,
  transfer: Transfer,
  trash: Trash,
  type: Type,
  unLock: UnLock,
  upload: Upload,
  url: Url,
  userAdd: AddUser,
  userRemove: RemoverUser,
  users: Users,
  video: Video,
  warning: Warning
};

type ApplicationIconProps = SvgIconProps & {
  /**
   * The name (key) of the icon to display from the IconMap
   *
   * @type {ApplicationIconName}
   */
  name: ApplicationIconName;
  /**
   * Size of the icon
   *
   * @type {("small" | "medium" | "large")}
   */
  size?: "small" | "medium" | "large";
  /**
   * An icon to stack on the top right of the icon. Useful for adding additional context, eg add (plus) or delete (x)
   *
   * @type {ApplicationIconName}
   */
  stackIcon?: ApplicationIconName;
  /**
   * Background of the stacked icon circle
   *
   * @type {SvgIconProps["color"]}
   * @default action
   */
  stackColor?: SvgIconProps["color"];
};

export const ApplicationIcon: FC<ApplicationIconProps> = (props) => {
  const { name, size, stackIcon, stackColor, ...restProps } = props;
  const Icon = iconMap[name];

  const fontSize = useMemo(() => {
    switch (size) {
      case "large":
        return 20;
      case "medium":
        return 18;
      case "small":
      default:
        return 16;
    }
  }, [size]);

  const renderedIcon = (
    <SvgIcon sx={{ fontSize }} {...restProps}>
      <Icon />
    </SvgIcon>
  );

  if (stackIcon) {
    const StackIcon = iconMap[stackIcon];

    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box
          sx={{
            bgcolor: `${stackColor || "action"}.main`,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: -4,
            top: -4,
            p: "2px"
          }}
        >
          <SvgIcon
            sx={{
              color: `${stackColor || "action"}.contrastText`,
              fontSize: fontSize * 0.5,
              width: fontSize * 0.5,
              height: fontSize * 0.5
            }}
            {...restProps}
          >
            <StackIcon />
          </SvgIcon>
        </Box>
        {renderedIcon}
      </Box>
    );
  }

  return renderedIcon;
};
