import React from "react";
import { Box, Collapse, IconButton, List, ListItemButton, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { WithPermissions } from "@packages/service-api";

import { MenuItem } from "@nutrien-operations/config";

import { ApplicationIcon, ApplicationIconName } from "../icons/ApplicationIcon";
import CollapsedSidebarItem from "./CollapsedSidebarItem";
import { styled } from "@mui/material/styles";

const RouterLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit"
}));

type Props = {
  applicationId: string;
  depth?: number;
  item: MenuItem;
  sidebarCollapsed?: boolean;
};

const SideBarMenuItem: React.FC<Props> = (props) => {
  const { applicationId, depth = 0, item, sidebarCollapsed = false } = props;
  const location = useLocation();

  const getInitials = (str: string) => {
    return str
      ?.split(" ")
      .map((s) => s[0]?.toUpperCase())
      .join("");
  };

  const isLinkActive = (path: string, exact?: boolean) => {
    if (exact) {
      return location.pathname === path;
    }

    return path !== "/" && location.pathname?.startsWith(path);
  };

  const determinePerformExactActiveLinkMatch = (item: MenuItem, depth: number) => {
    if (depth === 0) {
      // do not perform an exact check if this is the root item and the item has children
      if (!item.children || (Array.isArray(item.children) && item.children.length > 0)) {
        return false;
      }

      // if this is the root item and there are no children, perform an exact check
      return true;
    }

    // for all children items, always perform an exact link check
    return true;
  };

  const childrenCount = Array.isArray(item.children) ? item.children.length : 0;

  const determineDefaultCollapsed = (item: MenuItem) => {
    // if the current menu item is active, don't default collapse
    if (isLinkActive(item.path, true)) {
      return false;
    }

    // if the menu item has children, traverse the tree of children looking for an active child path.
    if (Array.isArray(item.children)) {
      let childActive = false;

      for (const child of item.children) {
        if (isLinkActive(child.path, true)) {
          childActive = true;
          break;
        }

        childActive = determineDefaultCollapsed(child);
      }

      // if one of the child paths is active, don't default collapse
      if (childActive) {
        return false;
      }
    }

    // finally if the menu item is configured to default collapse, do that, otherwise do nothing.
    return item.defaultCollapsed || false;
  };

  const [childrenCollapsed, setChildrenCollapsed] = React.useState<boolean>(
    determineDefaultCollapsed(item)
  );

  return (
    <WithPermissions
      applicationId={applicationId}
      hideNoPermissionsUI
      permissions={item.permission || []}
    >
      <>
        <RouterLink to={item.path}>
          <ListItemButton
            dense
            onClick={(event) => {
              if (childrenCount > 0) {
                // if sidebar is close let's navigate to default route
                if (!sidebarCollapsed) {
                  event.preventDefault();
                  event.stopPropagation();
                  setChildrenCollapsed((c) => !c);
                }
              }
            }}
            sx={(theme) => ({
              borderLeftColor: theme.palette.grey[300],
              borderLeftStyle: "solid",
              borderLeftWidth: depth > 0 ? 1 : 0,
              marginLeft: childrenCount > 0 ? 0 : theme.spacing(3 * depth),
              paddingLeft: childrenCount > 0 ? 0.4 : 2.5,
              backgroundColor: isLinkActive(
                item.path,
                determinePerformExactActiveLinkMatch(item, depth)
              )
                ? "primary.light"
                : undefined,
              ...(sidebarCollapsed
                ? {
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderRadius: 2
                  }
                : {}),

              "&:hover": {
                backgroundColor: "primary.light"
              }
            })}
          >
            {sidebarCollapsed ? (
              <CollapsedSidebarItem
                tooltip={item.label}
                label={getInitials(item.label)}
                icon={item.icon as ApplicationIconName}
              />
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "row" }}>
                {childrenCount > 0 && (
                  <IconButton
                    sx={(theme) => ({
                      border: "none",
                      borderRadius: 0,
                      padding: 0,
                      "&:hover": {
                        background: "none",
                        color: theme.palette.grey[500]
                      }
                    })}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      setChildrenCollapsed((c) => !c);
                    }}
                  >
                    <ApplicationIcon name={childrenCollapsed ? "chevron-right" : "chevron-down"} />
                  </IconButton>
                )}
                <ListItemText
                  primary={item.label}
                  sx={(theme) => ({
                    "& .MuiListItemText-primary": {
                      fontSize: theme.typography.fontSize,
                      fontWeight: depth === 0 ? "bold" : "normal"
                    }
                  })}
                />
              </Box>
            )}
          </ListItemButton>
        </RouterLink>
        {!sidebarCollapsed && Array.isArray(item.children) && (
          <Collapse in={!childrenCollapsed} timeout="auto" unmountOnExit>
            {item.children?.map((child, ci) => (
              <List component="nav" disablePadding key={ci}>
                <SideBarMenuItem
                  applicationId={applicationId}
                  item={child}
                  depth={depth + 1}
                  sidebarCollapsed={sidebarCollapsed}
                />
              </List>
            ))}
          </Collapse>
        )}
      </>
    </WithPermissions>
  );
};

export default SideBarMenuItem;
